/*
 * MAIN NET
 */

export const CHAIN_ID = 0xa86a;
export const CHAIN_ID_STRING = '0xa86a';
export const CHAIN_NAME = 'Avalanche Network';
export const RPC_URL = 'https://api.avax.network/ext/bc/C/rpc';
export const CONTRACT_ADDRESS = '0xc129E38f2e91AabaA0D6c7F8B3feb904a7E77797';

/*
 * TEST NET (FUJI)
 */

// export const CHAIN_ID = 0xa869;
// export const CHAIN_ID_STRING = '0xa869';
// export const CHAIN_NAME = 'Avalanche Fuji Network';
// export const RPC_URL = 'https://api.avax-test.network/ext/bc/C/rpc';
// export const CONTRACT_ADDRESS = '0xf896cA00E2DC4471964DFDbA0584f41F6c06A074';
